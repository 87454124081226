import * as React from 'react';
import { useEffect, useRef, useState, Fragment } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { VerticalNavbar, setActiveValueInNavItems, AppHeader, Text, ButtonGroup, Button } from "@nike/eds";
import{useNavigate} from 'react-router-dom';
// import SavedViewTable from './SavedViewTable.js';
// import SavedView from './SavedViewModal.js';
import { useAuthStore, selectIdName} from "@nike/aegis-auth-react";


export default function EmbedHome(){

      const dashboardRef = useRef([]);
      const [dashboardId, setDashboardId] = useState('c39a0306-0d03-495d-94dd-d6d31fb7373f');
      const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
      const [dashboardUrl, setDashboardUrl] = useState(null);
      const [embeddingContext, setEmbeddingContext] = useState(null);
      const navigate =useNavigate();
      // const useremail = useAuthStore(selectEmail);
      const user = useAuthStore(selectIdName)?.split(" ")[0];
      const [rav, setRAV] = React.useState(false);
    
     
    
      const [items, setItems] = useState([
        {
            id: "5",
            icon: "Home",
            label: "Home",
            active: false,
        },
        {
          id: "1",
          icon: "Desktop",
          label: "Compute",
          active: false,
        },
        {
          id: "6",
          icon: "Payment",
          label: "FinOps",
          active: false,
        },
        {
          id: "2",
          icon: "Bag",
          label: "Storage",
          active: false,
        },
       
      ]);
    
      const [bottomItems] = useState([

      ]);
    
    
     
    
      // Click event function example that sets the active value for a nav item
      // Updates the items array with the new items with active state
      const handleClick = (item) => {
        if (item.label === "Compute" ) {
         changeDashboard("b0128b9f-990d-4d82-84e2-1e0595bea5fb");
         //navigate('/compute');
        
        }
        if (item.label === "Home") {
           changeDashboard("c39a0306-0d03-495d-94dd-d6d31fb7373f");
           navigate('/');
        }
        if (item.label === "Storage") {
           window.location.href = "https://cloudstorage.nike.com/executive";
        
        }
        if (item.label === "FinOps") {
          window.location.href =
         "https://dash.finops.nikecloud.com/dashboard/Executive/?sheet=Executive+Overview";
      }
    
        const newItems = setActiveValueInNavItems(item.id, items);
        setItems(newItems);
      };
          
       const handleRAVClick = () => {

        setRAV(!rav);
        if(!rav){
        changeDashboard("1f4253c4-c2b4-48d9-8784-63af60f970ad");
        //navigate('/compute/rav');
        }else{
        changeDashboard("1f4253c4-c2b4-48d9-8784-63af60f970ad");
        //navigate('/compute/rav');
        }
    }


      useEffect(() => {
        const timeout = setTimeout(() => {
          const apiUrl= ("https://svdt6uj404.execute-api.us-west-2.amazonaws.com/embeddev/anonymous-embed-dev")
          fetch(apiUrl
          ).then((response) => response.json()
          ).then((response) => {
            setDashboardUrl(response.EmbedUrl)
          })
        }, 10);
        return () => clearTimeout(timeout);
      }, []);
    
      const createContext = async () => {
        const context = await createEmbeddingContext();
        setEmbeddingContext(context);
      }
    
      useEffect(() => {
        if (dashboardUrl) { createContext() }
      }, [dashboardUrl])
    
      useEffect(() => {
        if (embeddingContext) { embed(); }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [embeddingContext])
    
      const embed = async () => {
    
        const options = {
          url: dashboardUrl,
          container: dashboardRef.current,
          height: window.innerHeight * 0.94,
          width: window.innerWidth * 0.94,
        
        };
    
        const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
        setEmbeddedDashboard(newEmbeddedDashboard);
      };
      
      useEffect(() => {
            const handleResize = () => {
                if (embeddingContext) {
                    embed();
                }
            };
      
            window.addEventListener('resize', handleResize);
      
            return () => {
                window.removeEventListener('resize', handleResize);
            };
            // eslint-disable-next-line
        }, [embeddingContext, dashboardUrl]);
      
      useEffect(() => {
        if (embeddedDashboard) {
          embeddedDashboard.navigateToDashboard(dashboardId, {})
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dashboardId])
    
      const changeDashboard = async (value) => {
        const dashboardId = value
        setDashboardId(dashboardId)
      }
    
      const ToolbarPlaceHolder = ({ isDark, count }) => (
        <ButtonGroup>
        <Text font={"display-5"} as={"p"}>Welcome {user}!</Text>
        <span> &nbsp; </span>
              {/* <SavedView/>   */}
           {/* <PinnedOpportunityModal/> */}
           <Button variant="primary"  onClick={handleRAVClick} active={rav} title="RAV" size= "small">Resource Account View</Button>
       </ButtonGroup>

    );
    return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
        <AppHeader 
            appName="Nike Cloud Dashboard Hub"
            toolbarSlot={<ToolbarPlaceHolder isDark />}
            color={"black"}
        />
        <div style={{ display: 'flex', flex: 1 }}> 
            <Fragment>
            <div id="v-navigation-div">
                <VerticalNavbar
                id="v-nav-bar"
                appName="Operational Metrics"
                onNavigate={handleClick}
                items={items}
                bottomItems={bottomItems}
                />
            </div>
            </Fragment>
            <div style={{ flex: 1 }}> 
              {/* <SavedViewTable/>  */}
                <div ref={dashboardRef} />
           
            </div>
        </div>
        </div>
    );

}
