import { Link } from "@nike/eds";
import "@nike/eds/dist/index.css";

function Footer() {
  const handleSlackSupportNavigate = () => {
    window.open("https://nike.enterprise.slack.com/archives/C07KR9L9UF4", "_blank");
  };
  const handleSurveyNavigate = () => {
    window.open("https://forms.office.com/r/PmjfDkCfNL", "_blank");
  };
  const handleAWSCCOENavigate = () => {
    window.open("https://nike.enterprise.slack.com/archives/C06J38YK40M", "_blank");
  };
  const handleAzureCCOENavigate = () => {
    window.open("https://nike.enterprise.slack.com/archives/C4G9Q55M5", "_blank");
  };

  return (
    <div
      className="eds-flex eds-flex--justify-content-center eds-gap--32"
      id="footer"
    >
      <Link variant="secondary" onClick={handleSlackSupportNavigate}>
        Dashboard Support Slack
      </Link> |
      <Link variant="secondary" onClick={handleSurveyNavigate}>
        Feedback Survey
      </Link> |
      <Link variant="secondary" onClick={handleAWSCCOENavigate}>
        AWS CCOE
      </Link> |
      <Link variant="secondary" onClick={handleAzureCCOENavigate}>
        Azure CCOE
      </Link>
    </div>
  );
}

export default Footer;
